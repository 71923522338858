.title-view {
  display: flex;
  .title-font {
    width: 100%;
    text-align: center;
    position: relative;
    margin-top: 20px;
    padding: 10px 30px 0 30px;
    font-size: 40px;
    font-weight: 600;
    text-decoration: none;
    color: #ffffff;
    z-index: 1;
    text-shadow: rgb(120,200,204) 3px 0px 0px, rgb(120,200,204) 2.83487px 0.981584px 0px, rgb(120,200,204) 2.35766px 1.85511px 0px, rgb(120,200,204) 1.62091px 2.52441px 0px, rgb(120,200,204) 0.705713px 2.91581px 0px, rgb(120,200,204) -0.287171px 2.98622px 0px, rgb(120,200,204) -1.24844px 2.72789px 0px, rgb(120,200,204) -2.07227px 2.16926px 0px, rgb(120,200,204) -2.66798px 1.37182px 0px, rgb(120,200,204) -2.96998px 0.42336px 0px, rgb(120,200,204) -2.94502px -0.571704px 0px, rgb(120,200,204) -2.59586px -1.50383px 0px, rgb(120,200,204) -1.96093px -2.27041px 0px, rgb(120,200,204) -1.11013px -2.78704px 0px, rgb(120,200,204) -0.137119px -2.99686px 0px, rgb(120,200,204) 0.850987px -2.87677px 0px, rgb(120,200,204) 1.74541px -2.43999px 0px, rgb(120,200,204) 2.44769px -1.73459px 0px, rgb(120,200,204) 2.88051px -0.838247px 0px;
    .ja {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%);
      -webkit-transform: translate(-50%);
      font-size: 0.3em;
    }
  }
  .title-font::before {
    position: absolute;
    transform: skewX(-45deg);
    top: 0; bottom: 0; left: 0; right: 0;
    z-index: -1;
    background-color: #e14040;
    content: "";
    margin: 0 30px;
  }
  .title-decoration {
    width: 20px;
    position: relative;
    margin-top: 20px;
    padding: 10px 30px 0 30px;
    z-index: 1;
  }
  .title-decoration::before {
    background-color: #e14040;
    position: absolute;
    transform: skewX(-45deg);
    top: 0; bottom: 0; left: 0; right: 0;
    z-index: -1;
    content: "";
    margin: 0 30px;
  }
  .title-decoration.left {
    right: -50px;
  }
  .title-decoration.right {
    left: -50px;
  }
}

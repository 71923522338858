.timetable {
  .timetable-area {
    display: flex;
    overflow: scroll;
  }
  .date-area {
    width: 100%;
    text-align: center;
    font-size: larger;
    margin: 5px 0;
    font-weight: 600;
    .fs-sm {
      font-size: small;
    }
  }
}

.com-soon {
  width: 100%;
  height: 70vh;
  position: relative;
  .message {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    font-size: 2.3rem;
    color: #e14040;
    font-style: italic;
    text-shadow: 2px 2px 0 #ffffff, -2px 2px 0 #ffffff, 2px -2px 0 #ffffff, -2px -2px 0 #ffffff;
  }
};

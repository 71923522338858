.access {
  text-align: center;
  .access-info,.bus-info {
    .row {
      display: flex;
      margin: 10px;
      .col-head {
        background-color: #e14040;
        border-radius: 20px;
        color: #ffffff;
        width: 100px;
        font-weight: 600;
        height: fit-content;
        text-align: center;
      }
      .col {
        text-align: start;
        padding-left: 20px;
      }
    }
  }
  .map {
    iframe {
      max-width: 100%;
    }
  }
}
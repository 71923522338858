.artist-box {
  height: 300px;
  padding: 10px;
  margin: 5px;
  background-color: #ffffff;
  border-radius: 5px;
  border: solid 1px #e14040;
  box-shadow: 2px 2px 0 0 #e14040;
  .img-area {
    width: 100%;
    height: 180px;
    text-align: center;
    overflow: hidden;
    position: relative;
    img {
      object-fit: contain;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  .artist-name {
    color: #e14040;
    font-size: large;
    font-weight: 600;
    text-align: center;
    height: 40px;
    overflow: hidden;
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    line-height: 1.1;
    padding: 5px 0;
  }
  .tag-area {
    height: 30px;
    padding: 5px 0;
    border-top: solid 1px #c4c4c4;
    text-align: start;
    span {
      border-radius: 20px;
      padding: 2px 10px;
      margin-right: 5px;
      color: #686363;
    }
    .day1 {
      background-color: #ffcd35;
    }
    .day2 {
      background-color: #89ff35;
    }
    .day3 {
      background-color: #35d7ff;
    }
  }
}
@media (max-width: 550px) {
  .artist-box {
    height: 230px;
    .img-area {
      height: 120px;
    }
    .artist-name {
      height: 60px;
    }
    .tag-area {
      height: 30px;
    }
  }
}


.header-body {
  display: flex;
  height: 100%;
  justify-content: flex-end;
  align-items: center;
  .logo {
    height: 44px;
    margin-right: auto;
  }
  .hamburger-menu {
    position: relative;
    width: 50px;
    height: 50px;
    cursor: pointer;
    z-index: 999;
  }
  .hamburger-menu>span {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 35px;
    height: 4px;
    background: linear-gradient(135deg, rgb(92, 139, 255) 0%, rgb(255, 163, 240) 100%) 0px 0px / 100% 100%;
    background: -webkit-linear-gradient(135deg, rgb(92, 139, 255) 0%, rgb(255, 163, 240) 100%) 0px 0px / 100% 100%;
  }
  .hamburger-menu,
  .hamburger-menu>span {
    transition: all .4s;
  }
  .hamburger-menu>span:nth-child(1) {
    top: 20%;
  }
  .hamburger-menu>span:nth-child(2) {
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
  .hamburger-menu>span:nth-child(3) {
    bottom: 20%;
  }
    
  /* --- アニメーションパターン --- */
  #menu01.active span {
    left: 0;
  }
  #menu01.active span:nth-of-type(1) {
    transform: translateY(13px) rotate(-45deg);
  }
  #menu01.active span:nth-of-type(2) {
    left: 200%;
    opacity: 0;
    transform: translateY(10px);
    animation: active-menu01-bar01 .8s forwards;
  }
  @keyframes active-menu01-bar01 {
    100% {
      height: 0;
    }
  }
  #menu01.active span:nth-of-type(3) {
    transform: translateY(-13px) rotate(45deg);
  }
  .h-login-btn {
    background-color: transparent;
    font-weight: 600;
    margin-right: 10px;
  }
  @media (min-width: 992px) {
    .hamburger-menu {
      display: none;
    }
    .menu-nav-sp {
      display: none;
    }
  }
  @media (max-width: 992px) {
    .menu-nav-pc {
      display: none;
    }
    .menu-nav-sp {
      height: 100%;
    }
  }
}

.footer-body {
  width: 100%;
  padding: 5px 0;
  text-align: center;
  background-color: #e14040;
  .logo {
    width: 30%;
    max-width: 300px;
    min-width: 200px;
  }
  .footer-content {
    .modal-btn {
      color: #ffffff;
      background: none;
      border: none;
      cursor: pointer;
    }
  }
  .copy-rights {
    color: #ffffff;
  }
}
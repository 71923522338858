.artist-modal {
  text-align: center;
  overflow: scroll;
  .img-area {
    width: 100%;
    height: 300px;
    text-align: center;
    overflow: hidden;
    position: relative;
    img {
      object-fit: contain;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  .artist-name {
    color: #e14040;
    font-size: 30px;
    font-weight: 600;
    margin: 10px 0;
    overflow: hidden;
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
  }
  .concept {
    text-align: start;
    font-size: 20px;
    font-weight: 500;
  }
}
@media (max-width: 550px) {
  .artist-modal {
    .img-area {
      height: 200px;
    }
    .artist-name {
      font-size: x-large;
      line-height: 1.1;
    }
    .concept {
      line-height: 1.2;
    }
  }
}

.about {
  text-align: center;
  .about-view {
    position: relative;
    margin-top: 20px;
    img {
      width: 100%;
      height: 100%;
    }
    .concept {
      position: absolute;
      top: 50%;
      left: 50%;
      height: 100%;
      transform: translateY(-50%) translateX(-50%);
      -webkit-transform: translateY(-50%) translateX(-50%);
      color: #ffffff;
      width: 100%;
      -ms-writing-mode: tb-rl;
      writing-mode: vertical-rl;
      padding-right: 10px;
      text-align: justify;
      .concept-font {
        font-family: serif;
        font-size: 1.5rem;
        padding-top: 10px;
      }
    }
  }
  .swiper-container {
    .swiper {
      .swiper-slide {
        height: 100%;
        .img-area {
          height: 250px;
          text-align: center;
          overflow: hidden;
          position: relative;
          .event-card-img {
            object-fit: contain;
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
          }
        }
        @media (max-width: 800px) {
          .img-area {
            height: 200px;
          }
        }
        @media (max-width: 600px) {
          .img-area {
            height: 150px;
          }
        }
        @media (max-width: 400px) {
          .img-area {
            height: 100px;
          }
        }
      }
    }
    .custom-pagination {
      position: relative !important;
      margin-top: 10px;
      .swiper-pagination-bullet {
        margin: 0 4px;
      }
      .swiper-pagination-bullet-active {
        background-color: rgb(171, 60, 255);
      }
    }
  }
}

.home {
  position: relative;
  .top-view {
    width: 100%;
    height: auto;
    position: relative;
    img {
      width: 100%;
    }
    video {
      width: 100%;
    }
    .home-outline {
      font-size: 2rem;
      width: 100%;
      background-color: #ffffff;
      margin: 0 0 10px 0;
      text-align: center;
      padding: 20px 0 0 0;
      .day-svg {
        max-width: 60%;
      }
    }
  }
  .home-news {
    .content {
      padding: 5px;
      background-color: #ffffff;
      margin: 0 10px 10px 10px;
      border-radius: 5px;
      border: solid 1px #e14040;
      box-shadow: 2px 2px 0 0 #e14040;
      a {
        text-decoration: none;
      }
      .release-date {
        font-weight: 600;
        color: #e14040;
      }
      .news-title {
        padding-left: 20px;
        font-weight: 400;
        color: #e14040;
      }
    }
    .news-link {
      text-align: center;
      width: 100%;
      a {
        text-decoration: none;
      }
    }
  }
  .home-about {
    .about-box {
      width: 100%;
      border-radius: 5px;
      box-shadow: 0 0 12px 4px #b6b6b6;
      overflow: hidden;
      position: relative;
      img {
        width: 100%;
      }
      .logo {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        width: 100%;
      }
    }
  }
  .home-ticket {
    .ticket-view {
      height: 80px;
      padding: 20px;
      background-color: #ffffff;
      margin: 10px;
      border-radius: 10px;
      vertical-align: middle;
      position: relative;
      .ticket-content {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0;
      }
    }
  }
  .twitter-timeline {
    .timeline-area {
      height: 800px;
      overflow: scroll;
      margin-bottom: 20px;
    }
  }
  .view-container {
    padding: 20px;
    background-color: #ffffff;
    margin: 10px;
    border-radius: 10px;
    vertical-align: middle;
    position: relative;
    .ticket-content {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0;
    }
  }
  .ticket-circle-btn {
    z-index: 999;
    position: fixed;
    right: 30px;
    bottom: 30px;
    border: solid 5px #f72494;
    transition-duration: .4s;
    animation: anime1 0.5s ease 0s infinite alternate;
    .ticket-icon {
      width: 50%;
      height: 50%;
      color: #f72494;
    }
    .ticket-fs {
      font-size: small;
      color: #f72494;
    }
  }
  .ticket-circle-btn:hover {
    transform: scale(1.1);
  }
}
.intro-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  padding: 2em;
  width: 100%;
  height: 100%;
  background-color: #fff;
  border-radius: 1em;
  transform: translate(-50%, -50%);
  outline: transparent;
  overflow: scroll;
}
.intro-view {
  background-color: #000000;
  position: relative;
  .intro-logo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    color: #ffffff;
    img {
      width: 100%;
    }
    .anv-logo {
      text-align: center;
      font-weight: bold;
      text-shadow: 5px 5px 16px #ffffff;
      .logo-10 {
        font-size: 35vw;
        .th {
          font-size: 15vw;
        }
      }
      .aniv {
        font-size: 10vw;
        transform: translateY(-10px);
      }
    }
  }
}

@keyframes anime1 {
  from {
    transform: scale(0.9,0.9);
  }
  to {
    transform: scale(1,1);
  }
}


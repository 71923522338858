.goods-modal-base {
  background-color: #ffffff;
  margin: 5px;
  padding: 5px;
  text-align: center;
  .modal-btn {
    width: 100%;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
.goods-swiper {
  .swiper-slide {
    height: 50vh;
    .img-area {
      width: 100%;
      height: 100%;
      text-align: center;
      vertical-align: middle;
      position: relative;
      .goods-img {
        max-width: 100%;
        max-height: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
      }
    }
  }
  .custom-pagination {
    position: relative !important;
    margin-top: 10px;
    .swiper-pagination-bullet {
      margin: 0 4px;
    }
    .swiper-pagination-bullet-active {
      background-color: rgb(171, 60, 255);
    }
    
  }
}
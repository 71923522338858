.menu-sp-view {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100vh;
  background: url('../image/icon.png'), linear-gradient(135deg, #ff0404 0%, #ffffff) 0px 0px / 100% 100%;
  text-decoration: none;
  background-repeat: no-repeat;
  background-size: contain;
  animation-name: menuShow;
  animation-duration: 0.5s;
  overflow-y: scroll;
  .nav-ul {
    list-style: none;
    padding-inline-end: 40px;
    .nav-li {
      font-size: 1.4em;
      font-weight: bold;
      padding: 8px 3px;
      border-bottom: 1px solid #ffffff;
      .nav-link {
        text-decoration: none;
        color: #ffffff;
        text-shadow: 2px 2px 1px #ff0000;
        span {
          font-size: small;
          font-weight: 100;
          padding-left: 5px;
        }
      }
      .dropdown-ul {
        list-style: none;
        .dropdown-li {
          padding: 8px 0;
          .nav-link {
            text-decoration: none;
            span {
              font-size: small;
              padding-left: 5px;
            }
          }
        }
      }
    }
  }
}
@keyframes menuShow {
  0% {
    transform: translateX(100vw);
  }
  100% {
    transform: translateX(0);
  }
}

@media (min-width: 992px) {
  .menu-view {
    .nav-ul {
      display: flex;
      list-style: none;
      margin: 0;
      padding: 0 10px 0 0;
      .nav-li {
        text-align: center;
        position: relative;
        z-index: 1;
        background-color: #e14040;
        margin-left: 5px;
        .nav-link {
          display: block;
          padding: 0 1rem;
          font-weight: 600;
          font-size: larger;
          text-decoration: none;
          text-align: center;
          color: #ffffff;
          font-style: italic;
          div {
            font-size: 0.7rem;
            font-weight: normal;
          }
        }
        .dropdown-menu {
          display: none;
          position: absolute;
          top: 40px;
          left: 50%;
          transform: translateX(-50%);
          list-style: none;
          margin: 0;
          padding: 0;
          background-color: #e14040;
        }
      }
      .nav-li:hover .dropdown-menu {
        display: block;
      }
      .dropdown__li {
        background-color: #004d80;
        height: 60px;
        transition: all .3s;
        position: relative;
      }
      li:hover {
        transform: scale(1.1);
        transition: 0.6s;
      }
      li::before {
        position: absolute;
        left: -50px;
        top: 0;
        width: 0;
        height: 100%;
        background-color: #2c9caf;
        transform: skewX(45deg);
        z-index: -1;
        transition: width 1000ms;
      }
    }
  }
}
@media (max-width: 992px) {
  .menu-view {
    position: absolute;
    right: 0;
    width: 100%;
    height: 100vh;
    background-color: #737373;
    ul {
      list-style: none;
      padding: 20px;
      li {
        text-align: center;
        .nav-link {
          display: block;
          padding: 0 1rem;
          font-weight: 600;
          font-size: larger;
          text-decoration: none;
          text-align: center;
          div {
            font-size: 0.7rem;
            font-weight: normal;
          }
        }
      }
    }
  }
}

.table-base {
  width: 200px;
  height: 100%;
  border-radius: 20px;
  margin: 20px 5px 20px 0;
  position: relative;
  .table-head-cell {
    width: 100%;
    text-align: center;
    justify-content: center;
    border-radius: 20px 20px 0 0;
    .table-head-image {
      width: 100%;
      height: 100%;
      padding: 20px 0 0 0;
      img {
        width: 100%;
        height: calc(100% - 20px);
      }
    }
  }
  .table-head-cell.stage1 {
    background-color: #e00319;
  }
  .table-head-cell.stage2 {
    background-color: #01a1e6;
  }
  .table-head-cell.stage3 {
    background-color: #27923b;
  }
  .table-head-cell.stage4 {
    background-color: #fef101;
  }
  .table-head-cell.stage5 {
    background-color: #e80084;
  }
  .table-cell {
    width: 100%;
    height: 40px;
    background-color: transparent;
    position: relative;
    .time-left {
      position: absolute;
      top: -10px;
      left: -50px;
    }
    .time-right {
      position: absolute;
      top: -10px;
      right: -50px;
    }
  }
  .table-cell.border-nomal {
    border-top: solid 1px #ffffff;
    box-sizing: border-box;
  }
  .table-cell.border-bold {
    border-top: solid 3px #ffffff;
    box-sizing: border-box;
  }
  .content-cell {
    position: absolute;
    left: 0;
    display: block;
    background-color: #ffffff;
    text-align: center;
    padding: 5px;
    margin: 10px;
    border-radius: 5px;
    border: 1px solid #e14040;
    box-sizing: border-box;
    cursor: pointer;
    .time-string {
      color: #e14040;
      font-weight: 600;
      text-align: start;
    }
    .artist-name {
      color: #626262;
      font-weight: 600;
      overflow: hidden;
      display: -webkit-box;
      text-overflow: ellipsis;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      line-height: 1.1;
    }
  }
  .content-cell:hover {
    background-color: #e14040;
    border: 1px solid #ffffff;
    .time-string {
      color: #ffffff;
    }
    .artist-name {
      color: #ffffff;
    }
  }
  .content-cell.active {
    background-color: #e14040;
    border: 1px solid #ffffff;
    .time-string {
      color: #ffffff;
    }
    .artist-name {
      color: #ffffff;
    }
  }
}
.stage1 {
  background-color: #da5741;
}
.stage2 {
  background-color: #75b6e4;
}
.stage3 {
  background-color: #7ac26f;
}
.stage4 {
  background-color: #efd675;
}
.stage5 {
  background-color: #a573ad;
}
@media (max-width: 992px) {
  .table-base {
    min-width: 200px;
  }
}
@media (max-width: 500px) {
  .table-base {
    min-width: 49%;
  }
}

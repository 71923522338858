.yosenkai-view {
  padding-bottom: 50px;
  .top-view {
    width: 100%;
  }
  .tab {
    font-size: larger;
  }
  .edo {
    background-color: #ff6464;
  }
  .owari {
    background-color: #ffbf52;
  }
  .osaka {
    background-color: #5f7fff;
  }
  .yosen-rule {
    .rule-title {
      margin-top: 10px;
      padding: 20px;
      font-size: 40px;
      font-weight: 900;
      text-align: center;
      color: #ffffff;
      text-shadow: rgb(120,200,204) 3px 0px 0px, rgb(120,200,204) 2.83487px 0.981584px 0px, rgb(120,200,204) 2.35766px 1.85511px 0px, rgb(120,200,204) 1.62091px 2.52441px 0px, rgb(120,200,204) 0.705713px 2.91581px 0px, rgb(120,200,204) -0.287171px 2.98622px 0px, rgb(120,200,204) -1.24844px 2.72789px 0px, rgb(120,200,204) -2.07227px 2.16926px 0px, rgb(120,200,204) -2.66798px 1.37182px 0px, rgb(120,200,204) -2.96998px 0.42336px 0px, rgb(120,200,204) -2.94502px -0.571704px 0px, rgb(120,200,204) -2.59586px -1.50383px 0px, rgb(120,200,204) -1.96093px -2.27041px 0px, rgb(120,200,204) -1.11013px -2.78704px 0px, rgb(120,200,204) -0.137119px -2.99686px 0px, rgb(120,200,204) 0.850987px -2.87677px 0px, rgb(120,200,204) 1.74541px -2.43999px 0px, rgb(120,200,204) 2.44769px -1.73459px 0px, rgb(120,200,204) 2.88051px -0.838247px 0px;
    }
    .img {
      width: 100%;
    }
  }
}
.f-textshadow {
  color: #ffffff;
  text-shadow: rgb(107, 210, 255) 3px 0px 0px, rgb(107, 210, 255) 2.83487px 0.981584px 0px, rgb(107, 210, 255) 2.35766px 1.85511px 0px, rgb(107, 210, 255) 1.62091px 2.52441px 0px, rgb(107, 210, 255) 0.705713px 2.91581px 0px, rgb(107, 210, 255) -0.287171px 2.98622px 0px, rgb(107, 210, 255) -1.24844px 2.72789px 0px, rgb(107, 210, 255) -2.07227px 2.16926px 0px, rgb(107, 210, 255) -2.66798px 1.37182px 0px, rgb(107, 210, 255) -2.96998px 0.42336px 0px, rgb(107, 210, 255) -2.94502px -0.571704px 0px, rgb(107, 210, 255) -2.59586px -1.50383px 0px, rgb(107, 210, 255) -1.96093px -2.27041px 0px, rgb(107, 210, 255) -1.11013px -2.78704px 0px, rgb(107, 210, 255) -0.137119px -2.99686px 0px, rgb(107, 210, 255) 0.850987px -2.87677px 0px, rgb(107, 210, 255) 1.74541px -2.43999px 0px, rgb(107, 210, 255) 2.44769px -1.73459px 0px, rgb(107, 210, 255) 2.88051px -0.838247px 0px;
}
.edo-view {
  padding: 30px;
  background-color: #ffbfbf;
  border-right: 3px solid #ff6464;
  border-bottom: 3px solid #ff6464;
  border-left: 3px solid #ff6464;
}
.owari-view {
  padding: 30px;
  background-color: #ffdea5;
  border-right: 3px solid #ffbf52;
  border-bottom: 3px solid #ffbf52;
  border-left: 3px solid #ffbf52;
}
.osaka-view {
  padding: 30px;
  background-color: #bccaff;
  border-right: 3px solid #5f7fff;
  border-bottom: 3px solid #5f7fff;
  border-left: 3px solid #5f7fff;
}
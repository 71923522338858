.lineup {
  .button {
    border-radius: 20px;
    padding: 2px 10px;
    margin-right: 5px;
    color: #686363;
    width: 100%;
    border: 0;
    cursor: pointer;
  }
  .day1 {
    background-color: #ffcd35;
  }
  .day2 {
    background-color: #89ff35;
  }
  .day3 {
    background-color: #35d7ff;
  }

  .modal-base {
    .modal-btn {
      width: 100%;
      padding: 5px;
      background: none;
      border: none;
      cursor: pointer;
    }
  }
}
.modal-md {
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  padding: 2em;
  width: 70%;
  height: 70%;
  background-color: #fff;
  border-radius: 1em;
  transform: translate(-50%, -50%);
  outline: transparent;
  overflow: scroll;
  .modal-footer {
    text-align: center;
    position: absolute;
    top: 10px;
    right: 20px;
    cursor: pointer;
    .modal-close-btn {
      width: 100%;
      height: 30px;
      border-color: transparent;
      background-color: transparent;
      cursor: pointer;
    }
    .modal-close-btn::before, .modal-close-btn::after { /* 共通設定 */
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 5px; /* 棒の幅（太さ） */
      height: 40px; /* 棒の高さ */
      border-radius: 10px;
      background: linear-gradient(135deg, #cdffd8 0%, #94b9ff) 0px 0px / 100% 100%;
    }
     
    .modal-close-btn::before {
      transform: translate(-50%,-50%) rotate(45deg);
    }
     
    .modal-close-btn::after {
      transform: translate(-50%,-50%) rotate(-45deg);
    }
  }
}
.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 200;
  background: linear-gradient(135deg, #ffa9f8e6 0%, #fff7ade6 100%) 0px 0px / 100% 100%;
}
.artist-box-view {
  margin: 5px;
}

.outline {
  display: inline-block;
  margin: 20px;
  background-color: #ffffff;
  box-shadow: 5px 5px 12px 0 #a1a1a1;
  padding: 20px;
  text-align: center;
  img {
    max-width: 300px;
  }
  .outline-area {
    text-align: center;
    .row {
      display: flex;
      margin: 10px;
      .col-head {
        background-color: #e14040;
        border-radius: 20px;
        color: #ffffff;
        width: 100px;
        height: fit-content;
        font-weight: 600;
      }
      .col {
        text-align: start;
        padding-left: 20px;
      }
    }
  }
}

.title-head {
  text-align: center;
  display: inline-block;
  padding: 0 30px;
  margin: 5px 0;
  text-decoration: none;
  position: relative;
  z-index: 1;
  .title-gradetion {
    color: #ffffff;
    font-size: 2rem;
    font-weight: bold;
  }
}
.title-head::before {
  position: absolute;
  transform: skewX(-45deg);
  top: 0; bottom: 0; left: 0; right: 0;
  z-index: -1;
  background-color: #e14040;
  content: "";
}
@media (max-width: 992px) {
  .title-head {
    .title-gradetion {
      font-size: 1.5rem;
    }
  }
}

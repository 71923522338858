.news-list-area {
  margin: 10px 0;
  .content {
    padding: 5px;
    background-color: #ffffff;
    margin: 0 10px 10px 10px;
    border-radius: 5px;
    border: solid 1px #e14040;
    box-shadow: 2px 2px 0 0 #e14040;
    a {
      text-decoration: none;
    }
    .release-date {
      font-weight: 600;
      color: #e14040;
    }
    .news-title {
      padding-left: 20px;
      font-weight: 400;
      color: #e14040;
    }
  }
  .content:hover {
    box-shadow: none;
    transform: translateY(5px);
  }
  .pagination-area {
    position: relative;
    height: 50px;
    .pagination {
      position: absolute;
      color: #e14040;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}